import type { UrlObject } from 'url';
import { pathForInternalLink } from './pathForInternalLink';
import { shouldRouteInternally } from './shouldRouteInternally';

export function getRouteInternally(path: string | null | UrlObject) {
  const BASE_PATH = (
    process.env.NEXT_PUBLIC_BASE_PATH === '<empty>'
      ? ''
      : process.env.NEXT_PUBLIC_BASE_PATH
  ) as string;

  if (path === null) {
    return { shouldRouteInternally: false, internalLink: '' };
  }

  const routeInternally = shouldRouteInternally(BASE_PATH, path.toString());

  return {
    shouldRouteInternally: routeInternally,
    internalLink: routeInternally
      ? pathForInternalLink(BASE_PATH, path.toString())
      : '',
  };
}
